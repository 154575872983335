<template>
  <b-row>
    <b-col
      cols="12"
      class="mb-2"
    >
      <h5
        v-t="'modules.notification_campaigns._send.tabs.notification_content:subtitle'"
        class="mb-0"
      />
      <small
        v-t="'modules.notification_campaigns._send.tabs.notification_content:description'"
        class="text-muted"
      />
    </b-col>
    <b-col md="6">
      <i-input
        v-model="value.notifications[0].notification_channel"
        :errors="validationErrors.notification_channel"
        type="select"
        title="modules.notification_campaigns.inputs.channel"
        :options="notificationChannelOptions"
        :clearable="true"
        icon-options
        required
        option-label="title"
        :loading="isLoadingNotificationChannelOptions"
        :disabled="isLoadingNotificationChannelOptions"
        @input="v => fetchNotificationTemplateOptions(v)"
      >
        <!--        <template #list-footer>-->
        <!--          <p-->
        <!--            v-t="`modules.shipping_methods.inputs.courier:footer`"-->
        <!--            class="pl-50 pt-50"-->
        <!--          />-->
        <!--        </template>-->
      </i-input>
    </b-col>
    <b-col md="6">

      <i-input
        v-model="value.notifications[0].notification_template"
        :errors="validationErrors.notification_template"
        type="select"
        title="modules.notification_campaigns.inputs.template"
        :options="notificationTemplateOptions"
        :clearable="true"
        required
        label="label"
        :loading="isLoadingNotificationTemplateOptions"
        :disabled="isLoadingNotificationChannelOptions"
      >
        <template #list-header>
          <p class="pl-50 pt-50">
            <b-link :to="{name:'notification_templates.list'}">
              <span v-t="`modules.notification_campaigns.inputs.template:footer`" />
              <feather-icon
                :icon="$store.state.appConfig.layout.isRTL ? 'ArrowLeftIcon' : 'ArrowRightIcon'"
                class="ml-50"
              />
            </b-link>
          </p>
        </template>
      </i-input>
    </b-col>
    <template>
      <b-col
        md="6"
        class="mt-1"
      >
        <i-input
          v-model="value.add_discount"
          :errors="validationErrors.add_discount"
          type="switch"
          title="modules.notification_campaigns.inputs.add_discount"
        >
          <span class="switch-icon-left">
            <feather-icon icon="CheckIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="XIcon" />
          </span>
        </i-input>
      </b-col>

      <b-col md="6">
        <transition
          name="slide-fade"
          mode="out-in"
        >
          <i-input
            v-if="value.add_discount"
            v-model="value.notifications[0].coupon"
            :errors="validationErrors.coupon"
            type="select"
            title="modules.notification_campaigns.inputs.discount_coupon"
            :options="notificationTemplateOptions"
            :clearable="true"
            required
            label="label"
            :disabled="isLoadingNotificationChannelOptions"
            :lazy-module="couponStoreModule"
          >
            <!--            lazy-ajax="/dashbaord/v1/coupons/list"-->
            <!--            :lazy="q => fetchNotificationTemplateOptions(value.notification_channel)"-->
            <!--            <template #list-footer>-->
            <!--              <li-->
            <!--                class="add-new-client-header d-flex align-items-center my-50"-->
            <!--              >-->
            <!--                <feather-icon-->
            <!--                  icon="PlusIcon"-->
            <!--                  size="16"-->
            <!--                />-->
            <!--                <span-->
            <!--                  v-t="`modules.product_categories.actions.add_new`"-->
            <!--                  class="align-middle ml-25"-->
            <!--                />-->
            <!--              </li>-->
            <!--            </template>-->
          </i-input>
        </transition>
      </b-col>
      <b-col md="12">
        <Transition name="list-enter-up">
          <b-alert
            v-if="value.notifications[0].notification_template && value.add_discount && !value.notifications[0].notification_template.uses_coupon_variables"
            variant="warning"
            class="mt-1"
            show
            dismissible
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="AlertTriangleIcon"
              />
              <span class="ml-25">{{ $t('modules.notification_campaigns._messages.template_not_support_discounts') }}</span>
            </div>
          </b-alert>
        </transition>
      </b-col>
    </template>
  </b-row>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol, BAlert, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  onMounted, onUnmounted, ref, watch,
} from 'vue'
import store from '@/store'
import {
  commonRequestErrorsHandler,
} from '@core/utils/utils'
import notificationStoreModule from '@/views/models/notification_campaigns/notificationStoreModule'
import couponStoreModule from '@/views/models/coupons/couponStoreModule'

export default {
  components: {
    BLink,
    BAlert,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    couponStoreModule() {
      return couponStoreModule
    },
  },
  emits: ['input', 'select-channel'],
  setup(props, ctx) {
    const NOTIFICATIONS_STORE_MODULE_NAME = 'notifications'

    // Register module
    if (!store.hasModule(NOTIFICATIONS_STORE_MODULE_NAME)) store.registerModule(NOTIFICATIONS_STORE_MODULE_NAME, notificationStoreModule)
    onMounted(() => {
      if (!store.hasModule(NOTIFICATIONS_STORE_MODULE_NAME)) store.registerModule(NOTIFICATIONS_STORE_MODULE_NAME, notificationStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(NOTIFICATIONS_STORE_MODULE_NAME)) store.unregisterModule(NOTIFICATIONS_STORE_MODULE_NAME)
    })

    const setValue = (key, val) => {
      const v = { ...props.value }
      v.notifications[0][key] = val
      ctx.emit('input', v)
    }

    const isLoadingNotificationChannelOptions = ref(false)
    const notificationChannelOptions = ref([])
    const fetchNotificationChannelOptions = () => {
      isLoadingNotificationChannelOptions.value = true
      store.dispatch(`${NOTIFICATIONS_STORE_MODULE_NAME}/fetchNotificationChannels`)
        .then(response => {
          notificationChannelOptions.value = response.data.data
          if (notificationChannelOptions.value.length >= 1) {
            setValue('notification_channel', notificationChannelOptions.value[0])
            fetchNotificationTemplateOptions(notificationChannelOptions.value[0])
          }
        })
        .catch(e => {
          commonRequestErrorsHandler(e, true)
        })
        .finally(() => {
          isLoadingNotificationChannelOptions.value = false
        })
    }

    const isLoadingNotificationTemplateOptions = ref(false)
    const notificationTemplateOptions = ref([])

    const fetchNotificationTemplateOptions = notificationChannel => {
      if (!notificationChannel) {
        notificationTemplateOptions.value = []
        setValue('notification_template', null)
        return new Promise(resolve => { resolve([]) })
      }

      isLoadingNotificationTemplateOptions.value = true
      return store.dispatch(`${NOTIFICATIONS_STORE_MODULE_NAME}/fetchNotificationTemplates`, {
        // namespace: 'abandoned_cart',
        channel_type: notificationChannel.value,
      })
        .then(response => {
          notificationTemplateOptions.value = response.data.data
          if (notificationTemplateOptions.value.length >= 1) {
            setValue('notification_template', notificationTemplateOptions.value[0])
          }
          return response.data.data
        })
        .catch(e => {
          commonRequestErrorsHandler(e, true)
        })
        .finally(() => {
          isLoadingNotificationTemplateOptions.value = false
        })
    }

    fetchNotificationChannelOptions()

    watch(props.value, (newValue, oldValue) => {
      if (newValue.notifications[0].notification_channel.id !== oldValue.notifications[0].notification_channel.id) {
        if (newValue.notifications[0].notification_channel) {
          fetchNotificationTemplateOptions(newValue.notifications[0].notification_channel)
          setValue('notification_template', null)
        }
      }
    })

    return {
      validationErrors: {},
      isLoadingNotificationChannelOptions,
      isLoadingNotificationTemplateOptions,
      notificationChannelOptions,
      notificationTemplateOptions,
      fetchNotificationTemplateOptions,
      notificationStoreModule,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<template>
  <div>
    <!-- Filters -->
<!--    <filters-->
<!--      :category-filter.sync="categoryFilter"-->
<!--      :status-filter.sync="statusFilter"-->
<!--    />-->

    <!-- Table Container Card -->
    <model-list-table
      ref="modelListTable"
      :store-module-name="storeModuleName"
      :table-columns="tableColumns"
      :filters-list="filtersList"
      :query-params="getQueryParams"
      :add-action="true"
      :view-action="false"
      :preview-action="false"
      :edit-action="true"
      :delete-action="true"
      :extra-actions="[]"
      :is-searchable="true"
      :is-sortable="false"
      @update:meta="meta => metaData = meta"
    >
      <template #extra-container-actions="{isSortingActive, isTableBusy}">
        <!--        <template v-if="!isSortingActive">-->
        <!--          <template>-->
        <!--            <b-button-->
        <!--              variant="primary"-->
        <!--              class="mr-1"-->
        <!--              :disabled="isTableBusy"-->
        <!--            >-->
        <!--              <feather-icon-->
        <!--                icon="SendIcon"-->
        <!--                class="mr-50"-->
        <!--              />-->
        <!--              <span-->
        <!--                v-t="`modules.notification_campaigns.pages.add`"-->
        <!--                class="align-middle text-nowrap"-->
        <!--              />-->
        <!--            </b-button>-->
        <!--          </template>-->
        <!--        </template>-->
      </template>

      <template #actions="{item}">
        <b-button
          v-if="item.can_cancel"
          :id="`cancel-${item.id}-btn`"
          v-ripple.400="'rgb(49, 49, 49, 0.15)'"
          v-b-modal.cancel-item
          variant="link"
          class="p-1 text-secondary btn-link"
          @click="onClickCancel(item)"
        >
          <feather-icon
            icon="XIcon"
            size="18"
          />
          <div
            v-t="`modules.notification_campaigns.actions.cancel`"
            class="pt-50"
          />
        </b-button>
      </template>
    </model-list-table>

    <WizardModalSendNotification />
  </div>
</template>

<script>
import store from '@/store'
import { ref, onUnmounted } from 'vue'
import { formatDate } from '@core/utils/filter'
import ModelListTable from '@/views/models/common/ModelListTable.vue'
import {
  BButton,
  VBModal,
} from 'bootstrap-vue'
import { getLocale } from '@core/utils/utils'
import productOperationStoreModule from '@/views/models/products/productOperationStoreModule'
import notificationStoreModule from '@/views/models/notification_campaigns/notificationStoreModule'
import WizardModalSendNotification
from '@/views/models/notification_campaigns/modals/send/WizardModalSendNotification.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import Filters from './Filters.vue'
import notificationTemplateStoreModule from "@/views/models/notification_templates/notificationTemplateStoreModule";

export default {
  components: {
    WizardModalSendNotification,
    BButton,
    Filters,
    ModelListTable,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },

  methods: {
    getLocale,
  },
  setup() {
    const STORE_MODULE_NAME = 'notification_templates'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, notificationTemplateStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const modelListTable = ref(null)
    const categoryFilter = ref(null)
    const statusFilter = ref(null)
    const isProductOperationRequestLoading = ref(false)
    const metaData = ref({})
    const isRTL = store.state.appConfig.layout.isRTL
    const { t } = useI18nUtils()

    const tableColumns = [
      // {
      //   key: 'draggable',
      //   label: '',
      //   sortable: false,
      //   thClass: 'p-0',
      //   thStyle: 'width: 1px',
      //   tdClass: ['p-0', 'pl-1', 'draggable-handle', 'cursor-move'],
      // },
      {
        key: 'checkbox',
        label: '',
        sortable: false,
      },
      { key: 'id', sortable: true, label: '#' },
      {
        key: 'name', sortable: true, label: 'Name', // formatter: v => (isRTL ? v.ar : v.en),
      },
      {
        key: 'notification_channel', sortable: true, label: 'modules.notification_templates.$columns.channel_type',
      },
      {
        key: 'created_at',
        label: 'Creation Date',
        formatter: formatDate,
        sortable: true,
      },
      {
        key: 'updated_at',
        label: 'Last Update',
        formatter: formatDate,
        sortable: true,
      },
      { key: 'notification_template_status', sortable: true, label: 'Status' },
      { key: 'actions' },
    ]

    const filtersList = [categoryFilter, statusFilter]
    const getQueryParams = () => ({
      categories: categoryFilter.value,
      is_active: statusFilter.value,
      entity_type: router.currentRoute?.query?.entity_type || null,
      entities: router.currentRoute?.query?.entities || null,
    })

    const onClickCancel = item => {
      modelListTable.value.deleteItems(null, item, 'Yes, cancel', 'Canceled!', 'Item(s) has been canceled.')
    }

    return {
      modelListTable,
      isProductOperationRequestLoading,
      metaData,

      // Extra Filters
      categoryFilter,
      statusFilter,

      // Table props
      tableColumns,
      storeModuleName: STORE_MODULE_NAME,
      filtersList,
      getQueryParams,
      onClickCancel,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">

$gray-300: #dee2e6 !default;
$border-color: $gray-300 !default;

::v-deep .b-table {
  tbody {
    tr {
      //cursor: move;
    }
  }
}

.b-table {
  tbody {
    tr.ready-for-drag {
      transform: translateY(-4px);
      box-shadow: 0 3px 10px 0 $border-color;
      transition: all 0.2s;
    }
    tr {
      &:has(td .draggable-handle) {
        transform: translateY(-4px);
        box-shadow: 0 3px 10px 0 $border-color;
        transition: all 0.2s;
      }
    }
  }
}

.btn-link:hover {
  color: #5d6064 !important;
}
</style>

<template>
  <b-modal
    id="send-notification-modal"
    ref="modal"
    centered
    :title="$t(isAbandonedCartsReminder ? 'modules.notification_campaigns.pages.send_abandoned_carts_notification' : 'modules.notification_campaigns.pages.add')"
    sub-title="modules.notification_campaigns.pages.send_abandoned_carts_notification"
    size="lg"
    hide-footer
    :ok-title="$t('Create')"
    :cancel-title="$t('Cancel')"
    body-class="p-0"
    @show="onShow"
    @hidden="$emit('hidden')"
  >
    <b-overlay
      :show="isLoading"
      rounded="sm"
      variant="secondary"
      opacity="0.75"
    >
      <form-wizard
        ref="refFormObserver"
        :title="null"
        :subtitle="null"
        shape="square"
        :finish-button-text="expectedRecipientsCount ? $t('modules.notification_campaigns._send.submit_button', {count: expectedRecipientsCount}) : $t('Send')"
        :back-button-text="$t('Previous')"
        :next-button-text="$t('Next')"
        :start-index="0"
        :color="$themeColors.primary"
        @on-complete="onSubmit"
      >
        <!-- account details tab -->
        <tab-content
          :title="$t('modules.notification_campaigns._send.tabs.notification_content:title').toString()"
          :before-change="validationBasicSettingsForm"
          :icon="1/*itemData.id*/ ? 'feather icon-message-square' : null"
          :disabled="true"
        >
          <validation-observer
            ref="basicSettingsRules"
            tag="form"
          >
            <notification-info-tab
              ref="basicSettingsTab"
              v-model="itemData"
              @input="v => itemData=v"
            />
          </validation-observer>
        </tab-content>

        <!-- target tab -->
        <tab-content
          :title="$t('modules.notification_campaigns._send.tabs.target_audience:title').toString()"
          :before-change="validationShippingRatesForm"
          :icon="1/*itemData.id*/ ? 'feather icon-users' : null"
          :disabled="false"
        >
          <validation-observer
            ref="targetAudienceRules"
            tag="form"
          >
            <target-audience-tab
              v-model="itemData"
              :lock-entity-type="!!selectedEntityType"
              :target-audience-stats="targetAudienceStats"
              @updated-target="v => refreshTargetAudienceStats(v)"
            />
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>
  </b-modal>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import store from '@/store'
import {
  nextTick, onMounted, onUnmounted, ref,
} from 'vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import Ripple from 'vue-ripple-directive'
import NotificationInfoTab from '@/views/models/notification_campaigns/modals/send/NotificationInfoTab.vue'
import {
  commonRequestErrorsHandler, toast,
} from '@core/utils/utils'
import { $themeColors } from '@themeConfig'
import { BModal, BOverlay } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import notificationStoreModule from '@/views/models/notification_campaigns/notificationStoreModule'
import TargetAudienceTab from '@/views/models/notification_campaigns/modals/send/TargetAudienceTab.vue'

export default {
  components: {
    TargetAudienceTab,
    BOverlay,
    BModal,
    ValidationObserver,
    FormWizard,
    TabContent,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    NotificationInfoTab,
    // CompanyInformationTab,
    // IntegrationSettingsTab,
  },
  directives: {
    Ripple,
  },
  emits: ['submitted', 'hidden', 'update:is-visible'],
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    isAbandonedCartsReminder: {
      type: Boolean,
      default: false,
    },
    selectedEntityType: {
      type: String,
      default: null,
    },
    selectedEntityIds: {
      type: Array,
      default: null,
    },

    itemId: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    $themeColors() {
      return $themeColors
    },
  },
  mounted() {
    // Set value of refs
    this.refs.value = this.$refs
  },
  methods: {
    validationBasicSettingsForm() {
      return new Promise((resolve, reject) => {
        this.$refs.basicSettingsRules.validateWithInfo().then(({ success, /* errors, */ fields }) => {
          if (success) {
            resolve(true)
          } else {
            const failedFields = Object.keys(fields).filter(key => fields[key].failed)
            if (failedFields.length) {
              toast('warning', this.$t('message.fill_required_fields'))
              this.focusOnTabsOfErrors(failedFields, this.$refs.basicSettingsTab.$refs)
              reject()
            } else {
              resolve(true)
            }
          }
        })
      })
    },
    validationCompanyInfoForm() {
      return new Promise((resolve, reject) => {
        this.$refs.companyInfoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationIntegrationSettingsForm() {
      return new Promise((resolve, reject) => {
        this.$refs.integrationSettingsRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationShippingRatesForm() {
      return new Promise((resolve, reject) => {
        this.$refs.targetAudienceRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
  setup(props, ctx) {
    const NOTIFICATION_STORE_MODULE_NAME = 'notifications'

    // Register module
    if (!store.hasModule(NOTIFICATION_STORE_MODULE_NAME)) store.registerModule(NOTIFICATION_STORE_MODULE_NAME, notificationStoreModule)
    onMounted(() => {
      if (!store.hasModule(NOTIFICATION_STORE_MODULE_NAME)) store.registerModule(NOTIFICATION_STORE_MODULE_NAME, notificationStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(NOTIFICATION_STORE_MODULE_NAME)) store.unregisterModule(NOTIFICATION_STORE_MODULE_NAME)
    })

    // Data definition
    const { t } = useI18nUtils()
    const refs = ref({})
    const modal = ref()
    const emptyItemData = {
      notifications: [{
        notification_channel: null,
        notification_template: null,
        coupon: null,
      }],
      entity_type: props.selectedEntityType || null,
      entities: props.selectedEntityIds || [],
      schedule: null,
    }

    const itemData = ref({
      ...emptyItemData,
    })
    const isLoading = ref(true)
    const expectedRecipientsCount = ref(0)
    const targetAudienceStats = ref(null)
    const rates = ref([])
    const courierOptions = ref([])

    const resetItemData = () => {
      emptyItemData.entities = props.selectedEntityIds || []
      itemData.value = JSON.parse(JSON.stringify(emptyItemData))
      isLoading.value = false
      targetAudienceStats.value = null
    }

    resetItemData()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    const {
      validationErrors, fetch, performSave,
      focusOnTabsOfErrors,
    } = useModelUpdate(
      NOTIFICATION_STORE_MODULE_NAME,
      refs,
      itemData,
      () => {
        modal.value.hide()
        ctx.emit('update:is-visible', false)
        ctx.emit('submitted')
      },
      null,
      null,
      () => {
        // refs.value.value.sidebar.hide()
        itemData.value = JSON.parse(JSON.stringify(emptyItemData))

        ctx.emit('update:is-visible', false)
      },
    )

    const fetchItem = () => fetch().then(() => refFormObserver.value.activateAll())

    // const fetchOrder = () => fetch().then(() => refFormObserver.value.activateAll())

    const onShow = () => {
      resetForm()
      // fetchItem()

      nextTick(() => {
        refFormObserver.value.reset()
      })
    }

    const onSubmit = () => {
      isLoading.value = true
      performSave(true,
        () => {
          isLoading.value = false
        }, () => {
          isLoading.value = false
        }, () => {
          isLoading.value = false
        })
    }

    const refreshTargetAudienceStats = entities => {
      targetAudienceStats.value = null

      if (!entities || !entities.length) return

      store.dispatch(`${NOTIFICATION_STORE_MODULE_NAME}/getAudienceStats`, {
        channel_type: itemData.value.notifications[0].notification_channel?.value || null,
        entity_type: itemData.value.entity_type,
        entities,
      })
        .then(response => {
          targetAudienceStats.value = response
        })
        .catch(e => {
          commonRequestErrorsHandler(e)
        })
        .finally(() => {
        })
    }

    return {
      STORE_MODULE_NAME: NOTIFICATION_STORE_MODULE_NAME,
      itemData,
      courierOptions,
      rates,
      fetchItem,
      validationErrors,
      onShow,
      onSubmit,

      // Refs
      modal,
      refFormObserver,
      getValidationState,
      focusOnTabsOfErrors,
      resetForm,
      refreshTargetAudienceStats,

      refs,
      isLoading,
      expectedRecipientsCount,
      targetAudienceStats,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard';
[dir] .modal-content .vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  box-shadow: 0 3px 6px 0 rgba(73, 227, 112, 0.4);
}
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{ref:"form",staticClass:"repeater-form"},[_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('h5',{directives:[{name:"t",rawName:"v-t",value:('modules.notification_campaigns._send.tabs.target_audience:subtitle'),expression:"'modules.notification_campaigns._send.tabs.target_audience:subtitle'"}],staticClass:"mb-0"}),_c('small',{directives:[{name:"t",rawName:"v-t",value:('modules.notification_campaigns._send.tabs.target_audience:description'),expression:"'modules.notification_campaigns._send.tabs.target_audience:description'"}],staticClass:"text-muted mb-1"})]),_c('b-col',{attrs:{"md":"12"}},[_c('i-input',{ref:"itemEntityType",attrs:{"id":"itemEntityType","errors":[],"type":"select","reduce":function (o) { return o.key; },"options":[
          {
            key: 'CUSTOMER',
            label: _vm.$t('modules.notification_campaigns.inputs.entity_type:options.CUSTOMER')
          },
          {
            key: 'CART',
            label: _vm.$t('modules.notification_campaigns.inputs.entity_type:options.CART')
          } ],"title":"modules.notification_campaigns.inputs.entity_type","validation-rules":['required'],"translatable":false,"disabled":_vm.lockEntityType,"clearable":!_vm.lockEntityType},on:{"input":function (val) { return _vm.loadEntityOptions(val); }},model:{value:(_vm.value.entity_type),callback:function ($$v) {_vm.$set(_vm.value, "entity_type", $$v)},expression:"value.entity_type"}})],1),_c('b-col',{attrs:{"md":"12"}},[_c('Transition',{attrs:{"name":"fade-bottom"}},[(_vm.value.entity_type)?_c('i-input',{ref:"itemEntityId",attrs:{"id":"itemEntityId","errors":[],"type":"select","options":_vm.entityOptions,"reduce":function (o) { return String(o.id); },"title":"modules.notification_campaigns.inputs.entities","validation-rules":['required'],"translatable":false,"disabled":_vm.isEntityLoading,"input-bindings":{
            loading: _vm.isEntityLoading
          },"multiple":""},on:{"input":function (v) { return _vm.$emit('updated-target', v); }},model:{value:(_vm.value.entities),callback:function ($$v) {_vm.$set(_vm.value, "entities", $$v)},expression:"value.entities"}}):_vm._e()],1)],1),_c('b-col',{staticClass:"mb-2 ecommerce-application",attrs:{"cols":"12"}},[_c('hr',{staticClass:"my-2"}),_c('target-audience-stats',{attrs:{"stats":_vm.targetAudienceStats}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
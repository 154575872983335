import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import axios from '@/libs/axios'
import cartModel from './cartModel'
import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'

const {
  fetchList,
  fetchAll,
  fetchOne,
  create,
  update,
  deleteMany,
} = useModelStoreModule(cartModel)

const updateOrderStatus = (_, { orderId, status }) => new Promise((resolve, reject) => {
  axios
    .put(`${jwtDefaultConfig.BACKEND_HOST}/${jwtDefaultConfig.DASHBOARD_ENDPOINT}/${cartModel.name}/${orderId}`, { status })
    .then(response => {
      if (response && (response.status === 200)) {
        return resolve(cartModel.fromArray(response.data.data))
      }
      return reject(new Error('Operation Failed!'))
    })
    .catch(error => reject(error))
})

const updatePaymentStatus = (_, { orderId, status }) => new Promise((resolve, reject) => {
  axios
    .put(`${jwtDefaultConfig.BACKEND_HOST}/${jwtDefaultConfig.DASHBOARD_ENDPOINT}/${cartModel.name}/${orderId}`, { is_paid: status })
    .then(response => {
      if (response && (response.status === 200)) {
        return resolve(cartModel.fromArray(response.data.data))
      }
      return reject(new Error('Operation Failed!'))
    })
    .catch(error => reject(error))
})

const options = (_, queryParams) => new Promise((resolve, reject) => {
  axios
    .get(`${jwtDefaultConfig.BACKEND_HOST}/${jwtDefaultConfig.DASHBOARD_ENDPOINT}/${cartModel.name}/options`, { params: queryParams })
    .then(response => resolve(response))
    .catch(error => reject(error))
})

export default {
  name: 'carts',
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchList,
    fetchAll,
    fetchOne,
    create,
    update,
    updateOrderStatus,
    updatePaymentStatus,
    deleteMany,

    options,
  },
}

<template>
  <div>
    <b-row
      ref="form"
      class="repeater-form"
    >
      <b-col
        md="12"
        class="mb-1"
      >
        <h5
          v-t="'modules.notification_campaigns._send.tabs.target_audience:subtitle'"
          class="mb-0"
        />
        <small
          v-t="'modules.notification_campaigns._send.tabs.target_audience:description'"
          class="text-muted mb-1"
        />
      </b-col>
      <b-col md="12">
        <!-- Entity Type -->
        <i-input
          id="itemEntityType"
          ref="itemEntityType"
          v-model="value.entity_type"
          :errors="[]"
          type="select"
          :reduce="o => o.key"
          :options="[
            {
              key: 'CUSTOMER',
              label: $t('modules.notification_campaigns.inputs.entity_type:options.CUSTOMER')
            },
            {
              key: 'CART',
              label: $t('modules.notification_campaigns.inputs.entity_type:options.CART')
            },
          ]"
          title="modules.notification_campaigns.inputs.entity_type"
          :validation-rules="['required']"
          :translatable="false"
          :disabled="lockEntityType"
          :clearable="!lockEntityType"
          @input="val => loadEntityOptions(val)"
        />
      </b-col>

      <b-col md="12">
        <Transition name="fade-bottom">
          <!-- Entity ID -->
          <i-input
            v-if="value.entity_type"
            id="itemEntityId"
            ref="itemEntityId"
            v-model="value.entities"
            :errors="[]"
            type="select"
            :options="entityOptions"
            :reduce="o => String(o.id)"
            title="modules.notification_campaigns.inputs.entities"
            :validation-rules="['required']"
            :translatable="false"
            :disabled="isEntityLoading"
            :input-bindings="{
              loading: isEntityLoading
            }"
            multiple
            @input="v => $emit('updated-target', v)"
          />
        </Transition>
      </b-col>

      <b-col
        cols="12"
        class="mb-2 ecommerce-application"
      >
        <hr
          class="my-2"
        >
        <target-audience-stats
          :stats="targetAudienceStats"
        />
      </b-col>

    </b-row>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationProvider } from 'vee-validate'

import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getLocaleCurrency } from '@core/utils/utils'
import TargetAudienceStats from '@/views/models/notification_campaigns/modals/send/TargetAudienceStats.vue'
import store from '@/store'
import { onUnmounted, ref } from 'vue'
import cartStoreModule from '@/views/models/abandoned_carts/cartStoreModule'
import customerStoreModule from '@/views/models/customers/customerStoreModule'

export default {
  components: {
    TargetAudienceStats,
    BRow,
    BCol,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    value: {
      type: Object,
      required: true,
    },
    lockEntityType: {
      type: Boolean,
      default: false,
    },
    targetAudienceStats: {
      type: [Object, null],
      default: null,
    },
  },
  methods: {
    getLocaleCurrency,
  },
  setup(props, { emit }) {
    const CUSTOMER_STORE_MODULE_NAME = 'customers'
    const CART_STORE_MODULE_NAME = 'carts'

    // Register module
    if (!store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_STORE_MODULE_NAME, customerStoreModule)
    if (!store.hasModule(CART_STORE_MODULE_NAME)) store.registerModule(CART_STORE_MODULE_NAME, cartStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_STORE_MODULE_NAME)
      if (store.hasModule(CART_STORE_MODULE_NAME)) store.unregisterModule(CART_STORE_MODULE_NAME)
    })
    const entityOptions = ref([])
    const isEntityLoading = ref(false)
    const loadedEntityType = ref(null)

    const setValue = (key, val) => {
      const v = { ...props.value }
      v[key] = val
      emit('input', v)
    }
    const loadDynamicEntityOptions = entityType => {
      let storeModuleName
      let payload = {}
      switch (entityType) {
        case 'CUSTOMER':
          storeModuleName = CUSTOMER_STORE_MODULE_NAME
          payload = { is_active: 1 }
          break
        case 'CART':
          storeModuleName = CART_STORE_MODULE_NAME
          payload = { }
          break
        default:
          return new Promise(resolve => { resolve() })
      }

      isEntityLoading.value = true
      return store.dispatch(`${storeModuleName}/fetchList`, payload).then(response => {
        if (response.data.success) {
          entityOptions.value = response.data.data
          isEntityLoading.value = false
        }
      })
    }

    const loadEntityOptions = entityType => {
      if (entityType && (loadedEntityType.value === entityType)) {
        return
      }
      emit('updated-target', [])
      setValue('entities', [])
      loadDynamicEntityOptions(entityType)
      loadedEntityType.value = entityType
    }

    if (props.value.entity_type) {
      loadDynamicEntityOptions(props.value.entity_type).then(() => {
        if (props.value.entities.length > 0) {
          setTimeout(() => {
            emit('updated-target', props.value.entities)
          }, 1000)
        }
      })
    }

    return {
      isAutoRatesFeatureAvailable: false,
      validationErrors: {},
      item: {},
      entityOptions,
      isEntityLoading,
      loadEntityOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  transition: .35s height;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 600px;
}
</style>

<style lang="scss">
//[dir] .custom-control-primary .custom-control-input ~ .custom-control-label::before {
//  border-color: #00cfe8;
//  background-color: #00cfe8;
//}
//.custom-switch .custom-control-label .switch-icon-right {
//  color: #fff;
//}

[dir="ltr"] .form-control.is-invalid[type=number] {
  padding: 0.438rem 1rem;
}
[dir] .form-control.is-invalid[type=number] {
  background: unset;
}
</style>

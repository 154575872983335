export default {
  namespaced: true,
  name: 'carts',

  translatedAttributes: [
  ],

  /**
   * Prepare item data for backend request
   * @param item
   * @returns Object
   */
  toArray: item => ({
    customer_id: item.customer.id,
    address_id: item.shipping_address.id,
    payment_method_code: item.payment_method.code,
    shipping_method_code: item.shipping_method.code,

    items: item.items.map(cartItem => {
      return ({
        product_id: cartItem.product_id,
        price: (parseFloat(String(cartItem.price).replace(',', '')) || 0) * 100,
        qty: cartItem.selected_qty,
      });
    }),
  }),

  /**
   * Parse backend payload to item object
   * @param data
   * @param initialItemData
   * @returns Object
   */
  fromArray: (data, initialItemData = {}) => {
    const itemData = data
    itemData.subtotal.amount /= 100
    return {
      ...initialItemData,

      id: data.id,
      customer: data.customer,
      items_count: data.items_count || null,
      subtotal: itemData.subtotal,

      shipping_address: data.shipping_address,
      payment_method: data.payment_method,
      shipping_method: data.shipping_method,

      shipping_fees: data.shipping_fees,
      payment_fees: data.payment_fees,
      vat: data.vat,
      vat_rate: data.vat_rate,
      discount: data.discount,
      coupon_code: data.coupon_code,
      total: data.total,
      ip_address: data.ip_address,
      user_agent: data.user_agent,
      status: data.status,
      status_description: data.status_description,
      is_paid: !!data.paid_at,
      paid_at: data.paid_at,
      is_delivered: !!data.delivered_at,
      delivered_at: data.delivered_at,
      created_at: data.created_at,
      updated_at: data.updated_at,
      allowed_status_options: data.allowed_status_options,
      status_options_requires_shipment: data.status_options_requires_shipment,
      status_options_requires_waybill: data.status_options_requires_waybill,
      can_create_shipment: data.can_create_shipment,
      can_create_waybill: data.can_create_waybill,
      is_courier_controls_shipping: data.is_courier_controls_shipping,
      is_editable: data.is_editable,
      items: data.items.map(item => ({
        ...item,
        price: item.price.amount / 100,
        subtotal: item.subtotal.amount / 100,
        qty: item.available_qty,
        properties: item.properties || [],
      })),
      options: data.options,
      is_guest_order: data.is_guest_order,
      paymentsData: data.payments_data || [],
      shipments: data.shipments || [],
    }
  },

  resolveValidationErrors: errors => ({
    customer_id: errors.customer_id,
    shipping_address_id: errors.shipping_address_id,
    payment_method_code: errors.payment_method_code,
    shipping_method_code: errors.shipping_method_code,
    currency_code: errors.currency_code,
    status: errors.status,
  }),
}

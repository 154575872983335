<template>
  <div>
    <b-alert
      v-if="false"
      variant="secondary"
      class="mt-1"
      show
    >
      <div class="alert-body">
        <feather-icon
          class="mr-25"
          icon="InfoIcon"
        />
        <span
          v-t="`modules.notification_campaigns._messages.select_targets_to_see_stats`"
          class="ml-25"
        />
      </div>
    </b-alert>
    <b-row
      v-else
      class="match-height"
    >
      <b-col
        md="6"
      >
        <statistic-card-horizontal
          color="info"
          icon="UsersIcon"
          :statistic-title="$t('modules.notification_campaigns._send.stats.selected:title')"
          :statistic="stats ? stats.selectedCount : 0"
          :loading="!stats"
        />
      </b-col>
      <b-col
        md="6"
      >
        <statistic-card-horizontal
          color="primary"
          icon="UserCheckIcon"
          :statistic-title="$t('modules.notification_campaigns._send.stats.valid:title')"
          :statistic="stats ? stats.validCount : 0"
          :loading="!stats"
        />
      </b-col>
    </b-row>

    <template v-if="stats && stats.alerts.length">
      <b-alert
        v-for="(alert, i) in stats.alerts"
        :key="i"
        :variant="alert.type"
        class="mt-1"
        show
      >
        <div class="alert-body">
          <feather-icon
            class="mr-25"
            :icon="alert.icon"
          />
          <span
            class="ml-25"
            v-text="alert.message"
          />
        </div>
      </b-alert>
    </template>
  </div>
</template>

<script>
import {
  VBToggle,
  BAlert, BCol, BRow,
} from 'bootstrap-vue'
import { formatDate, formatDatetime } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { storagePath } from '@core/utils/utils'
import { resolveShipmentStatusVariant } from '@core/utils/constants'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  components: {
    StatisticCardHorizontal,
    BRow,
    BCol,

    BAlert,
  },
  props: {
    items: {
      type: Array,
      default: Array,
    },
    stats: {
      type: [Object, null],
      default: null,
    },
    isCalculating: {
      type: Boolean,
      default: false,
    },
  },
  methods: { resolveShipmentStatusVariant, formatDatetime, storagePath },
  setup(props, ctx) {
    const ImagePlaceholder = require('@/assets/images/pages/eCommerce/placeholder.webp')

    return {
      // UI
      ImagePlaceholder,

      // Filter
      formatDate,
    }
  },
}
</script>

<style lang="css">
[dir] .draft-shipment-items-list .v-select ul {
  position: absolute;
  top: auto;
  left: auto;
  width: inherit;
}

[dir] .ecommerce-application .draft-shipment-items-list .ecommerce-card.empty-card:hover {
  transform: unset;
  box-shadow: unset;
}
.ecommerce-application .draft-shipment-items-list.list-view .ecommerce-card {
  overflow: unset !important;
}

[dir="rtl"] .ecommerce-application .draft-shipment-items-list.list-view .shipment-item-card .card-body {
  border-left: unset;
}
[dir] .ecommerce-application .draft-shipment-items-list .ecommerce-card:hover {
  transform: unset;
  box-shadow: unset;
}
</style>

import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import notificationChannelModel from '@/views/models/notification_campaigns/notificationChannelModel'
import notificationTemplateModel from '@/views/models/notification_campaigns/notificationTemplateModel'
import notificationCampaignModel from '@/views/models/notification_campaigns/notificationCampaignModel'
import notificationCampaignNotificationModel from '@/views/models/notification_campaigns/notificationCampaignNotificationModel'

const {
  fetchAll,
  fetchOne,
  fetchOneWithMeta,
  create,
  update,
  deleteOne,
} = useModelStoreModule(notificationCampaignModel)

const {
  createChild,
  getAudienceStats = (_, params) => createChild(_, {
    id: 'draft',
    childResourceName: 'stats',
    payload: params,
  }),
} = useModelStoreModule(notificationCampaignModel, true)

const {
  fetchList: fetchNotificationChannels,
} = useModelStoreModule(notificationChannelModel)

const {
  fetchList: fetchNotificationTemplates,
} = useModelStoreModule(notificationTemplateModel)

const {
  fetchAllChildren: fetchCampaignNotifications,
} = useModelStoreModule(notificationCampaignNotificationModel)

export default {
  name: 'notification_campaigns',
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAll,
    fetchOne,
    fetchOneWithMeta,
    create,
    update,
    deleteMany: deleteOne, // This used by `useModelList`, but this model doesn't have a `deleteMany` action

    fetchNotificationChannels,
    fetchNotificationTemplates,
    getAudienceStats,
    fetchCampaignNotifications: (ctx, id, params) => fetchCampaignNotifications(ctx, id, 'notifications', params),
  },
}

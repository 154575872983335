import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import notificationTemplateModel from '@/views/models/notification_campaigns/notificationTemplateModel'
import notificationChannelModel from '@/views/models/notification_campaigns/notificationChannelModel'

const {
  fetchAll,
  fetchOne,
  create,
  update,
  deleteMany,
} = useModelStoreModule(notificationTemplateModel)

const {
  fetchList: fetchNotificationChannels,
} = useModelStoreModule(notificationChannelModel)

export default {
  name: 'notification_templates',
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAll,
    fetchOne,
    create,
    update,
    deleteMany,
    fetchOptions: (ctx, namespace = '') => fetchOne(ctx, `options/${namespace}`),

    fetchNotificationChannels,
  },
}

<template>
  <b-card no-body>
    <b-card-body class="d-flex justify-content-between align-items-center">
      <div class="truncate">
        <div
          v-if="loading"
          class="mb-25"
        >
          <b-badge
            pill
            variant="light-secondary"
            style="width: 120px; height: 2rem"
          >&nbsp;</b-badge>
        </div>
        <h2
          v-else
          class="mb-25 font-weight-bolder"
        >
          {{ statistic }}
        </h2>
        <span>{{ statisticTitle }}</span>
      </div>
      <b-avatar
        :variant="`light-${color}`"
        size="45"
      >
        <feather-icon
          size="21"
          :icon="icon"
        />
      </b-avatar>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BAvatar, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BCard,
    BCardBody,
    BAvatar,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
